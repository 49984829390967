import React from 'react'
import * as style from './hero.module.scss'


const Hero = ({titleOne, titleTwo}) => (
  <section className={style['hero']}>
    <div className="container">
      <h1 className={style['hero__heading']}>
        {titleOne && <span className="weight--semi-bold">{titleOne}</span>}
        {titleTwo && <span className="weight--thin">{titleTwo}</span>}        
      </h1>
    </div>
  </section>
)

export default Hero
