import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import LogoSvg from "../../images/logo.svg"
import * as style from "./header.module.scss"



const Header = ({ siteTitle }) => (
  <header className={style['siteHead']}>
        <Link
          to="/"
          className="site-head__logo"
        >
        <span className="visually-hidden">Back to homepage</span>
        <img src={LogoSvg} alt=""/>
        </Link>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
