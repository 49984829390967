import React from 'react';
import './loader.scss'


const Loader = () => {
  return (
    <div className='loader'>


    <svg viewBox="0 0 112 112">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="logo">
              <g>
                <polygon className="letter letter__f" fill="#8CBA80" fillRule="nonzero" points="26.1029536 77 26.1029536 56.7832031 45.9594937 56.7832031 45.9594937 53.1738281 26.1029536 53.1738281 26.1029536 36.609375 48 36.609375 48 33 22 33 22 77"></polygon>
                <polygon className="letter letter__f--faded"  fillOpacity="0.46" fill="#8CBA80" fillRule="nonzero" points="28.1029536 79 28.1029536 58.7832031 47.9594937 58.7832031 47.9594937 55.1738281 28.1029536 55.1738281 28.1029536 38.609375 50 38.609375 50 35 24 35 24 79"></polygon>
              </g>
              <g>
                <polygon className="letter letter__h" fill="#8CBA80" fillRule="nonzero" points="60.0175781 78 60.0175781 57.7832031 84.9824219 57.7832031 84.9824219 78 89 78 89 34 84.9824219 34 84.9824219 54.1738281 60.0175781 54.1738281 60.0175781 34 56 34 56 78"></polygon>
                <polygon className="letter letter__h--faded" fillOpacity="0.46" fill="#8CBA80" fillRule="nonzero" points="62.0175781 80 62.0175781 59.7832031 86.9824219 59.7832031 86.9824219 80 91 80 91 36 86.9824219 36 86.9824219 56.1738281 62.0175781 56.1738281 62.0175781 36 58 36 58 80"></polygon>              
              </g>
              <rect className="rectangle" stroke="#8BBA80" strokeWidth="4" x="2" y="2" width="108" height="108"></rect>
            </g>
        </g>
    </svg>
    </div>
  )
}

export default Loader