import React from 'react'
import * as style from './footer.module.scss'
import LinkedInSvg from "../../images/linkedin.icon.svg"


const Footer = () => (
  <footer className={style['siteFoot']}>
    <p>Design & Build By Fraser Harkness.</p>
    <p>Currently Working At <a href="https://digitonic.com" target="_blank" rel="noreferrer">Digitonic</a>.</p>
    <a href="https://www.linkedin.com/in/fraser-harkness-77763458/" target="_blank" rel="noreferrer">
      <span className="visually-hidden">My LinkedIn Profile.</span>
      <LinkedInSvg />
    </a>
  </footer>
)


export default Footer
